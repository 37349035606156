<template>
  <v-alert
    class="alert-position"
    :type="colorType"
    width="250px"
    dismissible
    v-model="isShow"
    >{{ title }}</v-alert
  >
</template>
<script>
export default {
  name: "Alert",
  props: ["title", "color-type", "alert-open"],
  data() {
    return {
      isShow: this.alertOpen,
    };
  },
  watch: {
    alertOpen: {
      handler(val) {
        this.isShow = val;
      },
    },
  },
};
</script>
<style lang="scss" scope>
.v-alert.alert-position {
  position: absolute;
  right: 20px;
  top: 30px;
}
</style>