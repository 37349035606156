<template>
  <div class="modify-info">
    <v-card elevation="0" max-width="450px">
      <v-card-title>{{ $t("__modify_account") }}</v-card-title>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="6">
            <v-text-field
              class="pt-0"
              :rules="[rules.required]"
              :label="$t('__first_name')"
              v-model="input.first_name"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              class="pt-0"
              :rules="[rules.required]"
              :label="$t('__last_name')"
              v-model="input.last_name"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <PhoneNumberInput
          :label="$t('__phone_number')"
          :required="true"
          v-model="input.phone_number"
        />
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-end">
              <v-btn
                text
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="passwordInput = {}"
              >
                {{ $t("__modify_password") }}
              </v-btn>
            </div>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t("__modify_password") }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  :label="$t('__old_password')"
                  type="password"
                  autofocus
                  :rules="[rules.required]"
                  v-model="passwordInput.oldPassword"
                ></v-text-field>
                <v-text-field
                  :label="$t('__new_password')"
                  type="password"
                  :rules="[rules.required]"
                  v-model="passwordInput.newPassword"
                ></v-text-field>

                <v-text-field
                  :label="$t('__confirm_new_password')"
                  type="password"
                  :rules="[rules.required, rules.checkPassword]"
                  v-model="passwordInput.comfirmNewPassword"
                ></v-text-field>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                {{ $t("__cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="updatePassword">
                {{ $t("__confirm") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <v-card-actions class="pl-4 pt-0">
        <v-btn color="primary" elevation="2" @click="setUserData">
          {{ $t("__confirm") }}
        </v-btn>
        <v-btn elevation="2" :to="{ name: 'Home' }">
          {{ $t("__cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Alert
      :title="$t('__modify_info_success')"
      :color-type="'success'"
      :alert-open="updateSuccess"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { adminAPI } from "@/plugins/service";
import PhoneNumberInput from "@/components/PhoneNumberInput.vue";
import Alert from "@/components/Alert.vue";

export default {
  name: "ModifyInfo",
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
      userData: "admin/data",
      userID: "admin/uid"
    })
  },
  components: {
    PhoneNumberInput,
    Alert
  },
  data() {
    return {
      dialog: false,
      updateSuccess: false,
      input: {
        last_name: "",
        first_name: "",
        phone_number: ""
      },
      passwordInput: {
        oldPassword: "",
        newPassword: "",
        comfirmNewPassword: ""
      },
      rules: {
        required: value => !!value || this.$t("__required"),
        checkPassword: value =>
          value === this.passwordInput.newPassword ||
          this.$t("__password_failed")
      }
    };
  },
  methods: {
    setUserData() {
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("admin/getUserIDToken")
        .then(idToken => {
          return adminAPI.setUser(idToken, this.userID, this.input);
        })
        .then(() => {
          this.updateSuccess = true;
          this.$store.dispatch("setIsLoading", false);
        })
        .catch(err => {
          console.error(err);
          this.$store.dispatch("setIsLoading", false);
        });
    },
    updatePassword() {
      this.dialog = false;
      this.$store.dispatch("setIsLoading", true);
      this.$store
        .dispatch("admin/updatePassword", {
          checkPassword: this.passwordInput.oldPassword,
          newPassword: this.passwordInput.newPassword
        })
        .then(() => {
          this.$store.dispatch("setIsLoading", false);
        });
    }
  },
  watch: {
    userData: {
      immediate: true,
      deep: true,
      handler(val) {
        this.input.first_name = val.first_name;
        this.input.last_name = val.last_name;
        this.input.phone_number = val.phone_number;
      }
    }
  }
};
</script>
